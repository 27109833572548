import React, { useState, useEffect } from 'react';
import './slider.css';
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from 'react-icons/fa';


const ImageSlider = ({type, Photos}) => {
    const [current, setCurrent] = useState(0);
    const length = Photos.length;

const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
};

const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
};

const [time, setTime] = useState(Date.now());

useEffect(() => {
        const interval = setInterval(() => setTime(Date.now()), 3500);
        nextSlide();
        return () => {
            clearInterval(interval);
        };
    }, [time]);
    

if (!Array.isArray(Photos) || Photos.length <= 0) {
    return null;
}



return (
    <section className= {type === 'profile' ? 'profileStyle' : 'certificateStyle'}> 
        <FaArrowAltCircleLeft className= {type === 'profile' ? 'no-arrow' : 'left-arrow'}  onClick={prevSlide} />
        <FaArrowAltCircleRight className={type === 'profile' ? 'no-arrow' : 'right-arrow'} onClick={nextSlide} />
        {Photos.map((slide, index) => {
            return (
                <div className={index === current ? 'slide active' : 'slide'} key={index}>
                {index === current && (
                <img src={slide.photo} alt='travel image' className={type === 'profile' ? 'profile-image' : 'certificate-image'} />
                )}
        </div>
        );
    })}
    </section>
);
};

export default ImageSlider;
