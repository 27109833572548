import React from 'react';

import styles from './Projects.module.css';
import mac from "../../images/projects/macbook.png";
import imac from "../../images/projects/imac.png";
import iphone from "../../images/projects/iphone.png";
import github from "../../images/Socialmedia/github.png";


const Projects = ({setPageName}) => {
    const pageName = "Projects"
    setPageName(pageName);
    return (
        <>
        <div className={styles.centerizer}>
            <div className={styles.macContainer}>
                <img src={mac} alt='mac' className={styles.mac}/>
                <div className={styles.macDescription}>
                    <p className={styles.macName}>Flo Blogs</p>
                    <p>Welcome to Flo Blogs, a dynamic website built with React JS and powered by Hygraph headless CMS. With its stylish Material UI design, Flo Blogs offers a seamless user experience for content creators and readers alike. Enjoy a visually appealing layout, intuitive navigation, and optimized performance across various devices. Whether you're a blogger looking for efficient content management or a reader seeking captivating articles, Flo Blogs invites you to join its vibrant community and explore a world of inspiration and knowledge.</p>
                    <div className={styles.linkCenterer}>
                        <a href='https://flo-blogs.netlify.app/' target="_blank" rel='noreferrer'><div className={styles.linkToSite}>Flo Blogs</div></a>
                        <a href='https://github.com/floKianB/Flo-Blogs' target="_blank" rel='noreferrer'><img className={styles.codeIcon} src={github} alt="code link"/></a>
                    </div>
                </div>
            </div>

                <div className={styles.iphoneContainer}>
                    <div className={styles.phoneDescription}>
                        <h2 className={styles.iphoneName}>Flo Weather</h2>
                        <p>
                            Welcome to Flo Weather, a user-friendly web app created with HTML, CSS, and JS. It's my first experience working with APIs, allowing you to access real-time weather info effortlessly. Stay updated on temperature, humidity, wind speed, and more for any location worldwide. Flo Weather seamlessly integrates with trusted weather API,<a class={styles.nameSite} target="_blank" rel='noreferrer' href='https://www.meteomatics.com/en/weather-api/?gclid=CjwKCAiAzrWOBhBjEiwAq85QZ1xeiaiEOnYJHy-O6nm3d-_4sx-mEFJUeos4sBqd_Sty0ZPVevwsMxoCskoQAvD_BwE'> Meteomatics</a>, providing accurate data to help you make informed decisions. Enjoy the simplicity and professionalism of Flo Weather as you stay ahead of changing weather conditions.                          
                        </p>
                        <div className={styles.linkCenterer}>
                            <a href='https://flo-weather.netlify.app/' target="_blank" rel='noreferrer'><div className={styles.linkToSite}>Flo Weather</div></a>
                            <a href='https://github.com/floKianB/WeatherApp-API' target="_blank" rel='noreferrer'><img className={styles.codeIcon} src={github} alt="code link"/></a>
                        </div>
                    </div>
                    <img src={iphone} alt='iphone' className={styles.iphone}/>
                </div>


                <div className={styles.imacContainer}>
                    <img src={imac} alt='imac' className={styles.imac}/>
                    <div className={styles.imacDescription}>
                        <p className={styles.macName}>Flo Notes</p>
                        <p>Welcome to Flo Notes, a sophisticated web application developed with React JS that revolutionizes your note-taking process. With Flo Notes, effortlessly store and access your notes directly on your browser, ensuring a secure and convenient experience. The application boasts a meticulously designed interface that offers extensive customization options, empowering you to personalize your note-taking environment to suit your exact needs. Benefit from the seamless integration of local storage, combined with the polished and professional design of Flo Notes, as you effortlessly capture, organize, and retrieve your thoughts with utmost efficiency.                        </p>
                        <div className={styles.linkCenterer}>
                            <a href='https://flo-notes.netlify.app/' target="_blank" rel='noreferrer'><div className={styles.linkToSite}>Flo Notes</div></a>
                            <a href='https://github.com/floKianB/flo-note' target="_blank" rel='noreferrer'><img className={styles.codeIcon} src={github} alt="code link"/></a>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
};

export default Projects;
