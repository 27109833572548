import React, { useState } from "react";

import styles from "./navBar.module.css";
import Logo from "../../images/NavigationBar/Logo.png";
import { CgMenuRound } from "react-icons/cg";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import pdf from "../../images/CV.pdf";
const Nav = ({pageName}) => { 
    const [menueSlider, setMenueSlider] = useState(false)
        return(
            <>
            <nav className={styles.NavBig}>
                <div className={styles.navigationBar}>
                    <img className={styles.logo} src={Logo} alt = "logo"/>
                    <ul className={styles.list}>
                        <li><Link className={pageName === "Kian Baban" ? styles.onPage : styles.usual} to="/"> Home</Link></li>
                        <li><Link className={pageName === "Portfolio" ? styles.onPage : styles.usual} to="/Portfolio">Portfolio</Link></li>
                        <li><Link className={pageName === "Projects" ? styles.onPage : styles.usual} to="/Projects">Projects</Link></li>
                        <li><Link className={pageName === "Contact Me" ? styles.onPage : styles.usual} to="/ContactUs">Contact Me</Link></li>
                    </ul>
                    <button className={styles.downloadResume} onClick={() => window.open(pdf)}>My Resume</button>
                </div>
            </nav>
            <nav className={styles.NavMin}>
                <div className={menueSlider ? styles.NavMinSlider : styles.noNavMinSlider}>
                    <AiOutlineCloseCircle onClick={() => setMenueSlider(false)} color={'white'} size={40} className={styles.closeButton} />
                    <div className={styles.navSliderLinks}>
                        <li><Link onClick={() => setMenueSlider(false)} className={pageName === "Kian Baban" ? styles.onPage : styles.usual} to="/"> Home</Link></li>
                        <li><Link onClick={() => setMenueSlider(false)} className={pageName === "Portfolio" ? styles.onPage : styles.usual} to="/Portfolio">Portfolio</Link></li>
                        <li><Link onClick={() => setMenueSlider(false)} className={pageName === "Projects" ? styles.onPage : styles.usual} to="/Projects">Projects</Link></li>
                        <li><Link onClick={() => setMenueSlider(false)} className={pageName === "Contact Me" ? styles.onPage : styles.usual} to="/ContactUs">Contact Me</Link></li>
                    </div>
                </div> 
                <CgMenuRound onClick={() => setMenueSlider(true)} color={'white'} size={42.5} className={styles.menueIcon}/>
                <img className = {styles.logo} src={Logo} alt = "logo"/>
            </nav>
            </>
        )
}
export default Nav;