import React from 'react';

import styles from "./home.module.css";

import ImageSlider from '../Widgets/Slider/ImageSlider';
import tmu from "../../images/Home/TMU-Logo.png";
import basketball from "../../images/Home/BasketBall.png";
import guitar from "../../images/Home/music.png";
import PorofilePicture01 from "../../images/Home/ProfileSlidePhotos/Kian01.jpg";
import PorofilePicture02 from "../../images/Home/ProfileSlidePhotos/Kian02.JPG";
import PorofilePicture03 from "../../images/Home/ProfileSlidePhotos/Kian03.JPG";
import PorofilePicture04 from "../../images/Home/ProfileSlidePhotos/Kian04.JPG";
import PorofilePicture05 from "../../images/Home/ProfileSlidePhotos/Kian05.JPG";
import PorofilePicture06 from "../../images/Home/ProfileSlidePhotos/Kian06.JPG";
import PorofilePicture07 from "../../images/Home/ProfileSlidePhotos/Kian07.JPG";
import PorofilePicture08 from "../../images/Home/ProfileSlidePhotos/Kian08.JPG";
import Certificate01 from "../../images/Home/Certificates/one.png";
import Certificate02 from "../../images/Home/Certificates/two.png";
import Certificate03 from "../../images/Home/Certificates/three.png";
import Certificate04 from "../../images/Home/Certificates/four.png";
import Certificate05 from "../../images/Home/Certificates/five.png";
import Certificate06 from "../../images/Home/Certificates/six.png";


const Cards = ({setPageName}) => {
    const pageName = "Kian Baban";
    setPageName(pageName);
    const listOfAboutMePhotos = [
        { photo: PorofilePicture01 },
        { photo: PorofilePicture02 },
        { photo: PorofilePicture03 },
        { photo: PorofilePicture04 },
        { photo: PorofilePicture05 },
        { photo: PorofilePicture06 },
        { photo: PorofilePicture07 },
        { photo: PorofilePicture08 },
    ]
    const listOfCertificates = [
        { photo: Certificate01 },
        { photo: Certificate02 },
        { photo: Certificate03 },
        { photo: Certificate04 },
        { photo: Certificate05 },
        { photo: Certificate06 },
    ]
        return (
            <>
            <div className={styles.HomeContainer}>

{/* About me section */}

                <div className={styles.aboutMeContainer}>
                    <ImageSlider className={styles.profilePic} type="profile" Photos={listOfAboutMePhotos}/>
                    <div className={styles.aboutMeTextContainer}>
                        <h1 className={styles.aboutMeTitle}>About Me</h1>
                        <p className={styles.descriptionAboutMe}>
                            I'm Kian, a Business Technology Management student and software developer studying at Toronto Metropolitan University. Passionate about leveraging technology to drive business growth, I combine my skills in software development, problem-solving, and strategic thinking to deliver innovative solutions. With a strong foundation in project management and a dedication to continuous learning, I aim to make a positive impact in the field of business and technology.
                        </p>
                    </div>
                </div>

{/* Education */}
                <div className={styles.EducationContainer}>
                        <div className={styles.EducationTextContainer}>
                            <h1 className={styles.EducationTitle}>Education</h1>
                            <p className={styles.EducationDescription}>
                            I am currently studying Business Technology Management at Toronto Metropolitan University. This program provides me with a solid foundation in project management, strategic planning, and leveraging technology to drive business growth. I am gaining a comprehensive understanding of the intersection between business processes and technological advancements, enabling me to deliver effective solutions aligned with organizational objectives.
                            </p>
                        </div>
                        <img src={tmu} alt="alt" className={styles.universityLogo}/>
                </div>

{/* slider linkedin accomplishments */}
            <div className={styles.slider}>
                <ImageSlider type="certificate" Photos={ listOfCertificates }/>
            </div>

{/* basketball */}

                    <div className={styles.basketballContainer}>
                        <div className={styles.basketballTextContainer}>
                            <h1 className={styles.basketballTopic}>Basketball</h1>
                            <p className={styles.bassketballDescription} >
                                Passionate about basketball, I inherited the love for the sport in my family. I played in Iran's top U-18 league and earned the title of Most Improved Player (MIP) in 2019. Committed to my physical well-being, I prioritize intense workouts and training. Through basketball, I've learned valuable lessons in teamwork, leadership, and maintaining peak performance.
                            </p>
                        </div>
                            <img src={basketball} alt='basketball' className={styles.basketball}/>
                    </div>

{/* guitar */}

                    <div className={styles.guitarContainer}>
                        <img src={guitar} alt='guitar' className={styles.guitar}/>
                        <div className={styles.guitarTextContainer}>
                            <h1 className={styles.guitarTopic}>Guitar</h1>
                            <p className={styles.guitarDescription} >
                                With a deep passion for the guitar since age 10, I have dedicated myself to mastering this captivating instrument. I have performed in numerous recitals and concerts, both as a soloist and as part of ensembles. These experiences have shaped me into a confident and expressive musician, using the guitar as my voice to connect with others through heartfelt music.
                            </p>
                        </div>
                    </div>

{/* For small displays */}    
            </div>
        </>
    );
}

export default Cards;